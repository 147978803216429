.header-navigation {
  height: 50px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: left;
  padding-left: 100px;
}

.default-link-text a {
  text-decoration: none;
}

.nav-link-container a {
  color: #333;
  text-decoration: none;
}

.bars-btn {
  border: none;
  background-color: #fff;
  font-size: 24px;
  margin: 0px 8px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-in-anim {
  animation: fadeIn 1s ease-in-out;
}

.nav-logo-text {
  font-size: 24px;
}

.active-tenant {
  background-color: #e81f27;
  color: #fff !important;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}
.logout-btn {
  text-decoration: underline;
  background-color: #fff;
  border: none;
  cursor: pointer;
}