.wide-modal .modal-dialog-centered {
    width: 90%;
    max-width: 90%;
    min-width: 90%;
}

.modal-backdrop {
    z-index: 9999;
}

.fade.modal.show {
    z-index: 9999;
}