body {
  padding: 0;
  margin: 0;
  background-color: #f9f9f9;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-group {
  margin-bottom: 15px;
}

.text-right {
  text-align: right;
}

.one-button-column {
  width: 40px;
  text-align: center;
}

.two-buttons-column {
  width: 90px;
  text-align: center;
}

.three-buttons-column {
  width: 120px;
  text-align: center;
}

.btn-primary {
  background-color: #e81f27;
  border-color: #e81f27;
}

.btn-primary:hover {
  background-color: #a7181d;
  border-color: #a7181d;
}

.btn-primary:focus-visible,
.btn-primary:active {
  background-color: #a7181d !important;
  border-color: #a7181d !important;
  box-shadow: 0 0 0 0.25rem rgb(197 0 0 / 25%) !important;
}

.btn-incomplete {
  background-color: #ff8533;
  border-color: #ff8533;
  color: #fff;
}

.btn-incomplete:hover {
  background-color: #e65c00;
  border-color: #e65c00;
  color: #fff;
}

.btn-incomplete:focus-visible,
.btn-incomplete:active {
  background-color: #e65c00 !important;
  border-color: #e65c00 !important;
  box-shadow: 0 0 0 0.25rem rgba(197, 89, 0, 0.25) !important;
  color: #fff !important;
}

.btn-accept {
  background-color: #339966;
  border-color: #339966;
  color: #fff;
}

.btn-accept:hover {
  background-color: #26734d;
  border-color: #26734d;
  color: #fff;
}

.btn-accept:focus-visible,
.btn-accept:active {
  background-color: #26734d !important;
  border-color: #26734d !important;
  box-shadow: 0 0 0 0.25rem rgba(7, 197, 0, 0.25) !important;
  color: #fff !important;
}

.btn-accept:disabled {
  color: #fff;
}

.btn-cancel {
  background-color: #efefef;
  border-color: #efefef;
  color: #4f4d4d;
}

.btn-cancel:hover {
  background-color: #d9d7d7;
  border-color: #d9d7d7;
}

.btn-cancel:focus-visible,
.btn-cancel:active {
  background-color: #d9d7d7 !important;
  border-color: #d9d7d7 !important;
}

.table-btn {
  padding: 0;
  width: 33px;
  height: 30px;
  text-align: center;
  vertical-align: middle;
  margin: 1px 2px;
}

.pull-right {
  float: right;
}

a {
  color: #e81f27;
}

a:hover {
  color: #e81f27;
}

.form-control:focus {
  border-color: #e81f27;
  box-shadow: 0 0 0 0.25rem rgb(197 0 0 / 25%);
}

.bread-crumbs svg {
  font-size: 12px;
  margin-left: 5px;
  margin-right: 5px;
}

.bread-crumbs a,
.bread-crumbs span {
  font-size: 18px;
}

input[type="checkbox"] {
  margin-right: 5px;
}

label {
  margin-bottom: 5px;
  font-weight: 500;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: #e81f27;
}

.max-width-sm-padding {
  width: 33%;
}

.flex-holder-between {
  justify-content: space-between;
  min-width: 991px;
}

.btn:disabled {
  background-color: grey !important;
  border-color: grey !important;
  opacity: 0.4 !important;
  cursor: not-allowed !important;
  pointer-events: unset !important;
}

.dzu-dropzone {
  overflow: auto !important;
  max-height: 250px;
  min-height: 50px !important;
}

.dzu-dropzone .dzu-previewContainer {
  padding: 5px 3% !important;
  min-height: unset !important;
}

.radio-margin {
  margin-left: 20px;
}

.table-select-list-width {
  width: 250px;
}

.table-checkbox {
  margin-left: 44%;
}

.column-checkbox {
  width: 150px;
  text-align: center !important;
}

.tabList button.Mui-selected {
  color: #e81f27;
}

.tabList span.MuiTabs-indicator {
  background-color: #e81f27;
}

.locked-icon {
  color: #e81f27;
  background-color: transparent;
  border: none;
  margin-right: 5px;
  margin-left: 5px;
}

.locked-icon-by-myself {
  color: rgb(173, 173, 173);
  background-color: transparent;
  border: none;
  margin-right: 5px;
  margin-left: 5px;
}

.inline-element {
  display: inline-block;
  margin-right: 10px;
}

.width-50 {
  width: calc(50% - 10px);
}

.width-25 {
  width: calc(25% - 10px);
}

.react-datepicker-popper {
  z-index: 12 !important;
}

.collapsed-items {
  padding: 10px;
  border: 1px solid #ced4da;
  border-top: none;
  border-radius: 0 0 5px 5px;
  margin: 0 0 10px;
  white-space: nowrap;
  display: block;
  overflow: auto;
  background-color: #f7f7f7;
}

.collapsed-item {
  width: fit-content;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  margin-left: 7px;
  margin-right: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #535353;
  display: inline-flex !important;
  background-color: #fff;
}

.collapsed-title {
  text-align: left;
}

.collapsed-item.comment-item {
  max-width: 300px;
  max-height: 150px;
  white-space: initial;
  overflow: auto;
}

.arrow-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: center;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
}

.arrow-container span {
  font-size: small;
}

.arrow-width {
  width: 100px;
  height: 30px;
}

.rect-div {
  width: 120px;
  height: 45px;
  align-self: center;
  font-size: small;
  font-weight: 500;
  font-size: 17px;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
}

.justify-center {
  justify-content: center;
}

.collapsed-container {
  padding: 15px;
  padding-bottom: 0;
}

.plus-btn {
  color: #e81f27;
  font-weight: bold;
  background: none;
  border: none;
}

.plus-btn:hover,
.plus-btn:active,
.plus-btn:focus-visible {
  color: #a7181d;
  font-weight: bold;
  background: none;
  border: none;
}

.light-header {
  font-weight: 400;
  font-size: large;
}

.text-color {
  color: #e81f27;
  cursor: pointer;
}

.emails-div {
  margin-top: 5px;
  margin-bottom: 30px;
}

.not-valid td {
  border-bottom: 2px solid #e81f27 !important;
}

.not-valid div {
  text-align: center;
  color: #e81f27;
}